@font-face {
    font-family: marmalade;
    src: url(fonts/Marmalede.ttf);
}
@font-face {
    font-family: alexbrush;
    src: url(fonts/AlexBrush-Regular.ttf);
}
@font-face {
    font-family: floralcapitals;
    src: url(fonts/FloralCapitals.ttf);
}

@page {
    size: A4;
    margin: 0;
}

// Variable overrides first
$primary: #ffbc8b;
$enable-shadows: true;
$prefix: "mo-";

// Then import Bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";

body {
    background-color: #f2be98;
    overflow-x: hidden;
}
:root {
    --mo-body-font-weight: 300;
    --mo-body-font-family: Montserrat, sans-serif;
}
a {
    text-decoration: none;
    color: inherit;
}
a:hover {
    text-decoration: underline;
}
div.viewPort {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
}
div.paper {
    background-color: #FFF2E7;
    border-top-left-radius: 30vh;
    border-top-right-radius: 30vh;
    min-height: 90vh;
    min-width: 60vh;
    max-width: 60vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: relative;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
}
div.innerPaper {
    position: absolute;
    height: calc(100% - 5vh);
    width: 50vh;
    border-top-left-radius: 25vh;
    border-top-right-radius: 25vh;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-left: 2px solid var(--mo-secondary-color);
    left: 5vh;
    top: 5vh;
    z-index: 0;
}
.title-cont {
    padding-top: 30vh;
}
.title-cont-bali {
    padding-top: 25vh;
}
.initial-font {
    font-family: floralcapitals;
    font-size: 25vh;
    line-height: .7em;
    transform: translateY(12%);
}
.initial-text {
    color: #ffbc8b;
}
.initial-img {
    width: 30vh;
    height: 30vh;
    background-image: url('logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.title-font {
    font-family: alexbrush;
    font-weight: 700;
}
.title-conj {
    font-size: 2rem;
    font-weight: 300;
    color: inherit;
}
.title-caption {
    font-size: .8rem;
}
.subtitle-font {
    font-family: marmalade;
}
.subtitle-text {
    font-size: 10vh;
}
.invite-text {
    font-size: 1.25rem;
}
.invite-text-cont {
    position: absolute;
    top: 50%;
    left: -11.8em;
    transform-origin: center;
    transform: rotate(270deg);
}
.flower-cont {
    position: absolute;
    top: 0;
    left: 50%;
    width: 75%;
    transform: translateX(-50%);
}
.flower-cont img {
    max-width: 100%;
    max-height: 100%;
    transform: rotate(-45deg);
    transform-origin: 50% 30vh;
    position: relative;
    top: -20px;
    left: -20px;
}
.location-title {
    font-size: 1.5rem;
}
.show-print {
    display: none;
}
@media screen and (orientation: portrait) {
    :root {
        --mo-body-font-size: 0.8rem;
    }
    div.paper {
        border-top-left-radius: 45vw;
        border-top-right-radius: 45vw;
        min-width: 90vw;
        max-width: 90vw;
    }
    div.innerPaper {
        width: 80vw;
        height: calc(100% - 5vw);
        border-top-left-radius: 40vw;
        border-top-right-radius: 40vw;
        left: 5vw;
        top: 5vw;
    }
    .flower-cont img {
        transform-origin: 50% 45vw;
    }
    .initial-font {
        font-size: 40vw;
    }
    .title-font {
        font-size: 3.5rem;
    }
    .title-caption {
        font-size: .6rem;
    }
    .title-cont {
        padding-top: 40vw;
    }
    .invite-text-cont {
        left: -9em;
    }
    .invite-text {
        font-size: var(--mo-body-font-size);
    }
}

@media screen and (orientation: portrait) and (max-width: 320px) {
    :root {
        --mo-body-font-size: 0.6rem;
    }
    .location-title {
        font-size: 1rem;
    }
    .title-caption {
        font-size: 0.4rem;
    }
    .title-font {
        font-size: 3rem;
    }
    .subtitle-text {
        font-size: 3rem;
    }
    h3 {
        font-size: 1.2rem;
    }
}

@media screen and (orientation: portrait) and (min-width: 600px) {
    .title-font {
        font-size: 5rem;
    }
    .invite-text-cont {
        left: -11.5em;
    }
    .invite-text {
        font-size: 1.25em;
    }
}

@media screen and (orientation: portrait) and (min-width: 800px) {
    :root {
        --mo-body-font-size: 1.2rem;
    }
    .title-font {
        font-size: 7rem;
    }
    .invite-text-cont {
        left: -11.4em;
    }
    .invite-text {
        font-size: 1.25em;
    }
}

@media screen and (min-width: 1360px) {
    :root {
        --mo-body-font-size: 0.9rem;
    }
    .initial-img {
        background-image: url('logo-1024.png');
    }
    .invite-text-cont {
        left: -12.7em;
    }
}

@media screen and (min-width: 1500px) {
    .px-md-5 {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }
}

@media screen and (min-width: 2560px) {
    :root {
        --mo-body-font-size: 1.5rem;
    }
    .title-font {
        font-size: 7rem;
    }
    .invite-text-cont {
        left: -11.8em;
    }
    .invite-text {
        font-size: 1.25em;
    }
}

@media screen and (min-width: 3840px) {
    :root {
        --mo-body-font-size: 2.4rem;
    }
    .title-font {
        font-size: 10rem;
    }
    .invite-text {
        font-size: 1.25em;
    }
}

@media print {
    html, body, div.viewPort {
        width: 210mm;
        max-width: 210mm;
    }
    div.viewPort {
        height: 297mm;
        max-height: 297mm;
    }
    .hide-print {
        display: none !important;
    }
    .show-print {
        display: block;
    }
    div.paper {
        border-top-left-radius: 89mm;
        border-top-right-radius: 89mm;
        min-height: 267mm;
        min-width: 178mm;
        max-width: 178mm;
    }
    div.innerPaper {
        height: 252mm;
        width: 148.5mm;
        border-top-left-radius: 74.25mm;
        border-top-right-radius: 74.25mm;
        left: 15mm;
        top: 15mm;
    }
    .title-cont {
        padding-top: 89mm;
    }
    .title-cont-bali {
        padding-top: 74.25mm;
    }
    .px-md-5 {
        padding-left: 15mm !important;
        padding-right: 15mm !important;
    }
    .flower-cont img {
        transform-origin: 50% 89mm;
    }
}